<template>
    <div class="case-content">
        <div class="case-title">案例管理</div>
        <div class="btn-case">
            <el-button size="medium" class="btn-green" @click="addCase">添加方案</el-button>
        </div>
        <el-table :data="caseTableList" style="width: 100%; flex: 1" height="1%" size="medium" header-cell-class-name="table-header-cell"
                  :header-cell-style="{fontWeight: 'normal', color: '#444', background: '#F5F7FA',height:'50px',fontSize: '16px'}"
                  :cell-style="{fontSize: '14px',color: '#666',height: '76px'}">
            <el-table-column label="序号"  align="center" width="80">
                <template slot-scope="scope">
                    <span class="case-title-table">{{scope.$index+1}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="cover_path" label="封面">
                <template slot-scope="scope">
                    <div class="cover-info">
                        <img :src="scope.row.cover_path" alt="">
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="logo_path" label="logo">
                <template slot-scope="scope">
                    <div class="logo-info">
                        <img :src="scope.row.logo_path?scope.row.logo_path:require('../../../assets/images/yike_logo.png')" />
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="name" label="案例标题"></el-table-column>
            <el-table-column prop="classification_name" label="案例分类" align="center"></el-table-column>
            <el-table-column prop="school_type_name" label="学校类型" align="center"></el-table-column>
            <el-table-column prop="create_time" label="发布时间" align="center"></el-table-column>
            <el-table-column label="操作" align="center" width="220">
                <template slot-scope="scope">
                    <el-button size="small"  type="primary" plain  @click="viewCase(scope.row)">查看</el-button>
                    <el-button size="small"  type="primary"  @click="editCase(scope.row)">编辑</el-button>
                    <el-button size="small"  type="danger"  @click="delCase(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                class="pages-center"
                :current-page="casePages.currentPageNum"
                :page-size="casePages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="casePages.total"
                @current-change="caseCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import {getCaseList,deleteCase} from '@/utils/apis'
    export default {
        name: "caseManageIndex",
        data(){
            return{
                caseTableList:[],
                //分页
                casePages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total:0
                },
            }
        },
        mounted() {
            this.getCaseList()
        },
        methods:{
            // 添加方案
            addCase(){
                this.$router.push({
                    path: '/caseManage/index/detail',
                })
            },
            // 查看
            viewCase(row){
                this.$router.push({
                    path: '/case/index',
                    query: {
                        id:row.id
                    }
                })
            },
            //编辑
            editCase(row){
                console.log(row)
                this.$router.push({
                    path: '/caseManage/index/detail',
                    query: {
                        id:row.id
                    }
                })


            },
            // 获取案例列表
            getCaseList(){
                let param = {
                    paging:1,
                    page: this.casePages.currentPageNum,
                    pageSize: this.casePages.eachPageNum,
                }
                getCaseList(param).then((res) => {
                    this.caseTableList = res.data.list
                    this.casePages.total = res.data.total;
                }).catch((err)=>{
                    console.log('err',err)
                })
            },
            // 删除
            delCase(row){
                let param = {
                    id:row.id
                }
                this.$confirm('是否删除该数据，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    deleteCase(param).then((res) => {
                        this.$message.success(res.msg);
                        this.getCaseList()
                    }).catch((err)=>{
                        console.log('err',err)
                    })
                }).catch(() => {
                    this.$message.info('已取消删除');
                })
            },
            // 切换页数
            caseCurrentChange(val){
                this.casePages.currentPageNum = val;
                this.getCaseList()
            }
        }
    }
</script>

<style scoped lang="scss">
    .case-content{
        display: flex;
        flex-direction: column;
        background: #fff;
        width: 100%;
        height: calc(100vh - 100px);
        padding:0 20px;
        box-sizing: border-box;
        .case-title{
            border-bottom: 1px solid #dcdcdc;
            padding: 30px 0 20px;
            box-sizing: border-box;
            font-size: 16px;
            color: #222;
        }
        .btn-case{
            display: flex;
            justify-content: flex-end;
            padding: 10px 0;
            box-sizing: border-box;
        }
        .cover-info{
            display: inline-block;
            width: 84px;
            height:60px;
        }
        .logo-info{
            display: inline-block;
            width: 42px;
            height: 42px;
        }
        img{
            max-width: 100%;
            max-height: 100%;
        }
        ::v-deep .el-table .cell{
            padding-right: 0;
        }
        ::v-deep .el-table .cell,::v-deep .el-table--border td:first-child .cell, ::v-deep  .el-table--border th:first-child .cell {
            padding-left:0;
        }
    }
</style>